@tailwind base;

@tailwind components;

@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Alegreya:ital,wght@0,400;0,500;0,700;0,900;1,400;1,500;1,600;1,700;1,800;1,900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
body {
  @apply bg-[#FCF9EB];

  font-family: "Lato";
  background-position: center center;
  background-image: url("../public/images/removedFirst.png");
  background-repeat:no-repeat;

}

.navbar_item {
  @apply  relative py-2 px-2 leading-normal no-underline flex items-center
}

#diamond-shield {
  @apply w-0 h-0 relative border-b-[40px] border-b-[red] border-[20px] border-solid border-transparent after:content-[''] after:absolute after:left-[-20px] after:w-0 after:h-0 after:border-t-[40px] after:border-t-[red] after:border-[20px] after:border-solid after:border-transparent after:top-5;
}

.shield {
  border: 3px solid gray;
  border-radius: 55% 55% 55% 55% / 24% 24% 88% 88%;
}

.noMatch {
  background-position: center center;
  background-image: url("../public/images/background.png");
  background-repeat:no-repeat;
}